export default async function postTobitAccessTokenForm(url: string, token: string, fieldName: string, frameId: string | number): Promise<boolean> {
    let id = `TappIframe_${frameId}`;

    if (!document.querySelector(`#${id}`)) {
        id = `TappIFrame_${frameId}`;
    }

    if (!document.querySelector(`#${id}`)) {
        return false;
    }

    const currForm = document.getElementById('accessTokenForm');

    if (currForm) {
        currForm.parentElement.removeChild(currForm);
    }

    const form = document.createElement('form');
    form.setAttribute('id', 'accessTokenForm');
    form.setAttribute('method', 'post');
    form.setAttribute('action', url);
    form.setAttribute('target', id);

    const hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', fieldName);
    hiddenField.setAttribute('value', token);

    form.appendChild(hiddenField);
    document.body.appendChild(form);
    form.submit();
    return true;
}
